import "./Styles/AddNewProgram.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import api from "../Contexts/BaseUrl";
import { useEffect, useState, useRef, createRef } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import Compressor from "compressorjs";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Select from "react-select";
import "./Styles/Recipe.css";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
// import AWS from "aws-sdk";

import {
  uploadFiles,
  uploadImage,
  isVimeoVideo,
} from "../Components/helpers/fileUpload";

export default function AddNewRecipe() {
  const VIDEO_URL = "https://d2zw1jyj1gqhys.cloudfront.net/";

  //Upload image to AWS Start

  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  // Upload Image to AWS Ends

  const hiddenInputImageUpload = useRef(null);
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");
  const [imageThumbnailLink, setImageThumbnailLink] = useState("");
  const [key, setKey] = useState("");

  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
    setImageSelected(false);
  };
  const handleChangeUploadedImage = (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      console.log("img::", newFile);

      setFile(newFile);
      setThumbnail(newFile);
      setFileupdated(newFile);
      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);
      setTempUploadedImageUrl(URL.createObjectURL(newFile));
      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
    }
    setShowReactCropper(true);
  };

  const handleCancelImage = () => {
    setTempUploadedImageUrl("");
    setFile(null);
    setThumbnail(null);
  };

  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [recipeTitle, setRecipeTitle] = useState("");
  const [recipeTimeLength, setRecipeTimeLength] = useState("");
  const [serving, setServing] = useState("");
  const [showReactCropper, setShowReactCropper] = useState(false);
  const [fileupdated, setFileupdated] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [inputs, setInputs] = useState([""]); // State to hold array of input values for Ingredients
  const [videoFile, setVideoFile] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [videoUploadProcess, setVideoUploadProcess] = useState(false);
  const [tempUploadedVideoUrl, setTempUploadedVideoUrl] = useState(null);
  const [uploadedVideoUrl, setUploadedVideoUrl] = useState(null);
  const [deleteExistingVideo, setDeleteExistingVideo] = useState(false);
  const [dayVideoAvailable, setDayVideoAvailable] = useState(null);
  const [isImageSelected, setImageSelected] = useState(false);
  const hiddenInputVideoUpload = useRef(null);
  const urlId = useParams();
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    console.log("state ", state);

    if (urlId.recipeId) {
      var user = JSON.parse(localStorage.getItem("auth"));
      api
        .get(
          state.isRecipe
            ? `/receipe/get-receipe-by-id?receipeId=${urlId.recipeId}`
            : `/InstagramReceipe/get-receipe-by-id?receipeId=${urlId.recipeId}`,
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        )
        .then((response) => {
          setRecipeTitle(response.data.title);
          setRecipeTimeLength(response.data.timeToPrepare);
          setServing(response.data.servings);
          setIsVideoLoading(true);
          setTempUploadedVideoUrl(response.data.videoUrl);
          setUploadedVideoUrl(response.data.videoUrl);
          setTempUploadedImageUrl(response.data.imageUrl);
          setImageThumbnailLink(response.data.thumbnailImageUrl);
          let categorieList = [];
          response.data.categoryDataList.map((element) =>
            categorieList.push(element.categoryId)
          );
          setSelectedValue(categorieList);
          setInputs(JSON.parse(response.data.ingredients));
          setMethods(JSON.parse(response.data.methods));
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickUploadVideo = (e) => {
    hiddenInputVideoUpload.current.click();
  };

  const handleChangeUploadedVideo = (e) => {
    if (e.target.files.length > 0) {
      setVideoFile(e.target.files[0]);
      const videoUrl = URL.createObjectURL(e.target.files[0]);
      setTempUploadedVideoUrl(videoUrl);
    }
    if (hiddenInputVideoUpload.current) {
      hiddenInputVideoUpload.current.value = "";
    }
  };

  // Function to handle changes in input values
  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  // Function to add a new input box
  const handleAddInput = () => {
    const newInputs = [...inputs, ""];

    setInputs(newInputs);
  };

  // Function to remove an input box
  const handleRemoveInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const [methods, setMethods] = useState([""]); // State to hold array of input values for methods

  // Function to handle changes in input values
  const handleMethodChange = (index, event) => {
    const newInputs = [...methods];
    newInputs[index] = event.target.value;
    setMethods(newInputs);
  };

  // Function to add a new input box
  const handleAddMethod = () => {
    const newInputs = [...methods, ""];
    setMethods(newInputs);
  };

  // Function to remove an input box
  const handleRemoveMethod = (index) => {
    const newInputs = [...methods];
    newInputs.splice(index, 1);
    setMethods(newInputs);
  };

  const cropperRef = createRef();

  const handleRemoveVideo = () => {
    if (dayVideoAvailable) {
      setDeleteExistingVideo(true);
    } else {
      setVideoFile(null);
      setTempUploadedVideoUrl(null);
      setUploadedVideoUrl(null);
    }
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
  }, []);
  const [timeValidation, setTimeValidation] = useState("");
  const [isTimeValid, setIsTimeValid] = useState(true);

  const handleSelectCategory = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }

    api
      .get("/receipe/get-category-list", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setAllCategories(response.data);
      });
  }, []);

  useEffect(() => {
    if (showReactCropper) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showReactCropper]);

  const cancleCropperModal = () => {
    setShowReactCropper(false);
    setTempUploadedImageUrl("");
  };

  const compressImage = async (blob, size) => {
    return new Promise((resolve) => {
      new Compressor(blob, {
        // Adjust compression settings as needed
        quality: 0.9,
        maxWidth: size,
        maxHeight: size,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(async (blob) => {
        // Further compress the cropped image
        const compressedBlob = await compressImage(blob, 1000);
        const thumbnailBlob = await compressImage(blob, 300);
        const fixedAspectRatioImage = new File(
          [compressedBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );
        const fixedThumbnailImage = new File(
          [thumbnailBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );
        setTempUploadedImageUrl(URL.createObjectURL(fixedAspectRatioImage));
        setFile(fixedAspectRatioImage);
        setThumbnail(fixedThumbnailImage);
      }, fileupdated.type);
    }
    setShowReactCropper(false);
  };

  const hitEditRecipeApi = async (
    recipeTitle,
    recipeTimeLength,
    serving,
    selectedValue,
    ingredients,
    methods,
    categoryDataList,
    imageUrl,
    thumbnailImageUrl,
    videoUrl,
    id
  ) => {
    await api
      .post(
        state.isRecipe
          ? "/Receipe/update-receipe"
          : "InstagramReceipe/update-receipe",
        {
          title: recipeTitle,
          timeToPrepare: recipeTimeLength,
          servings: serving,
          categoryList: selectedValue,
          ingredients: JSON.stringify(ingredients),
          methods: JSON.stringify(methods),
          categoryDataList: categoryDataList,
          imageUrl: imageUrl,
          thumbnailImageUrl: thumbnailImageUrl,
          videoUrl: videoUrl,
          id: id,
          // "createdDate": "0001-01-01T00:00:00"
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsLoading(false);
        toast.success("Recipe Edit Successfully", {
          position: "top-right",
          autoClose: 2000,
          theme: "dark",
        });
        state.isRecipe ? navigate("/recipe") : navigate("/instagram-recipe");
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  const handleCreateRecipe = async (e) => {
    e.preventDefault();
    // validateTimeInput();
    if (isTimeValid) {
      setIsLoading(true);
      if (!file && !tempUploadedImageUrl) {
        setImageSelected(true);
        setIsLoading(false);

        return;
      } else if (videoFile == null && !uploadedVideoUrl) {
        let thumbnailResponse;
        let imageResponse;
        if (file) {
          imageResponse = await uploadImage(file);
          thumbnailResponse = await uploadImage(thumbnail);
        }

        if (urlId.recipeId) {
          hitEditRecipeApi(
            recipeTitle,
            recipeTimeLength,
            serving,
            selectedValue,
            inputs,
            methods,
            null,
            file ? imageResponse.Location : tempUploadedImageUrl,
            file ? thumbnailResponse.Location : imageThumbnailLink,
            null,
            urlId.recipeId
          );
        } else {
          ///TODO : do not use if alse use state.isRecipe? "/receipe/create-receipe": "/receipe/create-instagram-receipe",
          if (state.isRecipe) {
            await api
              .post(
                state.isRecipe
                  ? "/receipe/create-receipe"
                  : "InstagramReceipe/createreceipe",
                {
                  Title: recipeTitle,
                  TimeToPrepare: recipeTimeLength,
                  Servings: serving,
                  CategoryList: selectedValue,
                  Ingredients: JSON.stringify(inputs),
                  Methods: JSON.stringify(methods),
                  ImageUrl: imageResponse.Location,
                  ThumbnailImageUrl: thumbnailResponse.Location,
                },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((response) => {
                setIsLoading(false);
                toast.success("Recipe Created Successfully", {
                  position: "top-right",
                  autoClose: 2000,
                  theme: "dark",
                });
                state.isRecipe
                  ? navigate("/recipe")
                  : navigate("/instagram-recipe");
              })
              .catch((err) => {
                setIsError(true);
                setIsLoading(false);
              });
          } else {
            console.log("add instagram recipe ........");
          }
        }
      } else {
        setVideoUploadProcess(true);
        setIsVideoLoading(true);
        let urlVideo;
        if (videoFile) {
          urlVideo = await uploadFiles(videoFile);
        }

        /** This is for upload video in aws  */

        let thumbnailResponse;
        let imageResponse;
        if (file) {
          imageResponse = await uploadImage(file);
          thumbnailResponse = await uploadImage(thumbnail);
        }

        if (urlId.recipeId) {
          hitEditRecipeApi(
            recipeTitle,
            recipeTimeLength,
            serving,
            selectedValue,
            inputs,
            methods,
            null,
            file ? imageResponse.Location : tempUploadedImageUrl,
            file ? thumbnailResponse.Location : imageThumbnailLink,
            videoFile ? urlVideo.Key : uploadedVideoUrl,
            urlId.recipeId
          );
        } else {
          await api
            .post(
              state.isRecipe
                ? "/receipe/create-receipe"
                : "InstagramReceipe/create-receipe",
              {
                Title: recipeTitle,
                TimeToPrepare: recipeTimeLength,
                Servings: serving,
                CategoryList: selectedValue,
                Ingredients: JSON.stringify(inputs),
                Methods: JSON.stringify(methods),
                ImageUrl: imageResponse.Location,
                ThumbnailImageUrl: thumbnailResponse.Location,
                VideoUrl: urlVideo.Key,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              setIsLoading(false);
              toast.success("Recipe Created Successfully", {
                position: "top-right",
                autoClose: 2000,
                theme: "dark",
              });
              state.isRecipe
                ? navigate("/recipe")
                : navigate("/instagram-recipe");
            })
            .catch((err) => {
              setIsError(true);
              setIsLoading(false);
            });
        }
      }
    } else {
      setTimeValidation("Please enter valid time format");
    }
  };

  var data = [];
  for (var i = 0; i < allCategories.length; i++) {
    data.push({
      value: allCategories[i].id,
      label: allCategories[i].name,
    });
  }

  return (
    <div className="addNewProgramPage">
      {isSuccess && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Your program has been created
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              successfully and saved as Draft.
            </li>
            <button
              onClick={() => navigate(-1)}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#ffba26",
                color: "#0a2e28",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendMedium500",
                fontSize: 14,
                borderRadius: 4,
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}
      <div style={{ display: "flex" }}>
        {state.isRecipe ? (
          <LeftNavigationBar recipe={1} />
        ) : (
          <LeftNavigationBar instagramRecipe={1} />
        )}

        <div className="mainBody">
          <div
            onClick={() =>
              urlId.recipeId
                ? navigate(`/preview-recipe/${urlId.recipeId}`)
                : navigate("/recipe")
            }
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
            {urlId.recipeId ? "Edit Recipe" : "Add New Recipe"}
          </li>
          <input
            type="file"
            accept="image/*"
            ref={hiddenInputImageUpload}
            onChange={handleChangeUploadedImage}
            style={{ display: "none" }}
          />
          <form
            onSubmit={handleCreateRecipe}
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            <div style={{ display: "flex" }}>
              {showReactCropper && (
                <div id="modal10" className="cropper-modal-wrapper">
                  <Cropper
                    ref={cropperRef}
                    zoomTo={0}
                    initialAspectRatio={16 / 11}
                    aspectRatio={16 / 11}
                    preview=".img-preview"
                    src={tempUploadedImageUrl}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    className="croppper-body"
                  />
                  <button
                    onClick={cancleCropperModal}
                    className="cropper-cancel-btn"
                  >
                    Cancel
                  </button>
                  <button className="cropper-submit" onClick={getCropData}>
                    Save
                  </button>
                </div>
              )}
              {tempUploadedImageUrl?.length === 0 ? (
                <div
                  onClick={handleClickUploadImage}
                  style={{
                    width: 200,
                    background: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <img
                    key={key}
                    src={uploadImageIcon}
                    alt="uploadIcon"
                    style={{
                      maxHeight: 150,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 40,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 14,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Upload Image
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 40,
                    }}
                  >
                    Upload the thumb of the recipe
                  </li>
                  {isImageSelected || tempUploadedImageUrl ? (
                    <li
                      style={{
                        marginTop: 20,
                        fontFamily: "LexendMedium500",
                        color: "#FF0000",
                        fontSize: 16,
                      }}
                    >
                      Please add image also!
                    </li>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                <div>
                  <div onClick={handleClickUploadImage} style={{}}>
                    <img
                      src={tempUploadedImageUrl}
                      alt="uploadedImage"
                      style={{
                        width: 200,
                        height: 200,
                        resize: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <button
                    onClick={handleCancelImage}
                    style={{
                      backgroundColor: "white",
                      paddingTop: 5,
                      paddingBottom: 5,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 10,
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              )}
              <div style={{ marginLeft: 30 }}>
                <input
                  required
                  placeholder="Recipe Title"
                  value={recipeTitle}
                  onChange={(e) => setRecipeTitle(e.target.value)}
                  style={{
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <input
                  required
                  placeholder="Time to prepare"
                  value={recipeTimeLength}
                  onChange={(e) => setRecipeTimeLength(e.target.value)}
                  style={{
                    marginTop: 25,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <input
                  required
                  placeholder="Servings"
                  value={serving}
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^[0-9\b]+$/;
                    if (value === "" || regex.test(value)) {
                      setServing(value);
                      setIsErrorNumber(false);
                    } else {
                      setIsErrorNumber(true);
                    }
                  }}
                  style={{
                    marginTop: 25,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                {isErrorNumber && (
                  <div
                    style={{
                      paddingTop: 5,
                      color: "red",
                    }}
                  >
                    Please enter number only
                  </div>
                )}
              </div>
              <div style={{ marginLeft: 70 }}>
                {!isLoading ? (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                    type="submit"
                  >
                    {urlId.recipeId ? "Save" : "Create"}
                  </button>
                ) : (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                    type="button"
                  >
                    <Spinner
                      style={{
                        color: "white",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </button>
                )}
                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    "Failed creating the recipe!"
                  </li>
                )}
              </div>
            </div>

            <div>
              <br />
              <Select
                className="dropdown-recipe"
                placeholder="Select Categories"
                value={data.filter((obj) => selectedValue.includes(obj.value))} // set selected values
                options={data} // set list of the data
                onChange={handleSelectCategory} // assign onChange function
                isMulti
                isClearable
                style={{
                  width: 585,
                }}
              />
            </div>
            <div>
              <input
                type="file"
                accept="video/mp4"
                ref={hiddenInputVideoUpload}
                onChange={handleChangeUploadedVideo}
                style={{ display: "none" }}
              />
              {tempUploadedVideoUrl === null ? (
                <div
                  onClick={handleClickUploadVideo}
                  style={{
                    cursor: "pointer",
                    width: 200,
                    marginTop: 10,
                    background: "#ffffff",
                    padding: "1px 5px",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="uploadImageIcon"
                    style={{
                      maxHeight: 150,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 40,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      textAlign: "center",
                      marginTop: 25,
                    }}
                  >
                    Upload Video
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 20,
                    }}
                  >
                    Upload the whole video of the recipe
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "black",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 20,
                    }}
                  >
                    *Only mp4 format is supported
                  </li>
                </div>
              ) : (
                <div
                  style={{
                    borderStyle: "none",
                    width: "100%",
                    paddingTop: urlId.recipeId ? 20 : 0,
                  }}
                >
                  {uploadedVideoUrl ? (
                    <>
                      {/* <VimeoPlayer
                        video={tempUploadedVideoUrl}
                        autoplay
                        className="video-wrapper"
                      /> */}
                      <video width="250" height="200" controls>
                        <source
                          src={`${
                            VIDEO_URL + isVimeoVideo(tempUploadedVideoUrl)
                          }`}
                        />
                      </video>

                      <button
                        onClick={handleRemoveVideo}
                        style={{
                          display: "block",
                          marginLeft: "60px",
                          marginTop: 10,
                          fontFamily: "LexendRegular400",
                          fontSize: 14,
                        }}
                      >
                        Remove Video
                      </button>
                    </>
                  ) : (
                    ""
                  )}

                  {videoFile &&
                    (!isVideoLoading ? (
                      <>
                        <video
                          style={{
                            width: 250,
                            height: 250,
                            borderStyle: "none",
                            display: "block",
                          }}
                          controls
                        >
                          <source src={tempUploadedVideoUrl} type="video/mp4" />
                        </video>
                        <button
                          onClick={handleRemoveVideo}
                          style={{
                            display: "block",
                            marginLeft: "60px",
                            marginTop: 10,
                            fontFamily: "LexendRegular400",
                            fontSize: 14,
                          }}
                        >
                          Remove Video
                        </button>
                      </>
                    ) : (
                      <>
                        <video
                          style={{
                            width: 250,
                            height: 250,
                            borderStyle: "none",
                            display: "block",
                          }}
                          controls
                        >
                          <source src={tempUploadedVideoUrl} type="video/mp4" />
                        </video>
                        <button
                          onClick={handleRemoveVideo}
                          style={{
                            display: "block",
                            marginLeft: "60px",
                            marginTop: 10,
                            fontFamily: "LexendRegular400",
                            fontSize: 14,
                          }}
                        >
                          Remove Video
                        </button>
                      </>
                    ))}
                </div>
              )}
            </div>
            <li
              onClick={() => console.log(file)}
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 24,
                marginTop: 30,
                marginBottom: 25,
              }}
            >
              Recipe Description
            </li>
            <li
              onClick={() => console.log(file)}
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 20,
              }}
            >
              Ingredients
            </li>
            {inputs.map((input, index) => (
              <div key={index} style={{ display: "flex" }}>
                <input
                  type="text"
                  placeholder="Ingredients"
                  value={input}
                  onChange={(event) => handleInputChange(index, event)}
                  style={{
                    marginTop: 25,
                    width: 545,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                {inputs.length > 1 && (
                  <div
                    onClick={() => handleRemoveInput(index)}
                    style={{
                      marginTop: 25,
                      marginLeft: 20,
                      cursor: "pointer",
                      width: 35,
                      height: 35,
                      borderRadius: 20,
                      borderStyle: "solid",
                      borderColor: "#FF0000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RiDeleteBin6Line size={20} style={{ color: "#FF0000" }} />
                  </div>
                )}
              </div>
            ))}
            <button
              onClick={handleAddInput}
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                height: 50,
                width: 585,
                borderStyle: "none",
              }}
            >
              <AiOutlinePlus size={18} />
              <li
                style={{
                  fontFamily: "LexendMedium500",
                  fontSize: 16,
                  marginLeft: 10,
                }}
              >
                Add New Ingredients
              </li>
            </button>
            <li
              onClick={() => console.log(file)}
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 20,
                marginTop: 25,
              }}
            >
              Methods
            </li>
            {methods.map((input, index) => (
              <div key={index} style={{ display: "flex" }}>
                <input
                  type="text"
                  placeholder="Methods"
                  value={input}
                  onChange={(event) => handleMethodChange(index, event)}
                  style={{
                    marginTop: 25,
                    width: 545,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                {methods.length > 1 && (
                  <div
                    onClick={() => handleRemoveMethod(index)}
                    style={{
                      marginTop: 25,
                      marginLeft: 20,
                      cursor: "pointer",
                      width: 35,
                      height: 35,
                      borderRadius: 20,
                      borderStyle: "solid",
                      borderColor: "#FF0000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RiDeleteBin6Line size={20} style={{ color: "#FF0000" }} />
                  </div>
                )}
              </div>
            ))}
            <button
              onClick={handleAddMethod}
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                height: 50,
                width: 585,
                borderStyle: "none",
              }}
            >
              <AiOutlinePlus size={18} />
              <li
                style={{
                  fontFamily: "LexendMedium500",
                  fontSize: 16,
                  marginLeft: 10,
                }}
              >
                Add New Methods
              </li>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
