import { useEffect, useState } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import "./Styles/AllPrograms.css";
import searchIcon from "../Images/Programs/search.png";
import { useNavigate } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { toast } from "react-toastify";

export default function AllPrograms() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  const [allPrograms, setAllPrograms] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [isDeleteProgram, setIsDeleteProgram] = useState(false);
  const [deleteProgramId, setDeleteProgramId] = useState(0);

  const pageNext = () => {
    if (pagination.totalPages > pagination.currentPage) {
      setPage(page + 1);
    }
  };

  const pagePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const pageChangeInput = (e) => {
    if (e.target.value > pagination.totalPages) {
      setPage(pagination.totalPages);
    } else {
      setPage(e.target.value);
    }
  };
  var urlParams;

  if (searchInput.length > 0) {
    urlParams = new URLSearchParams({
      programName: searchInput,
    }).toString();
  } else {
    urlParams = new URLSearchParams({
      pageNumber: page,
      pageSize: 1000,
    }).toString();
  }

  const tableDataStyles = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    paddingLeft: 10,
    // height:'auto',
  };

  var url =
    `/workoutPrograms/get-all-programs?` + urlParams + "&IsComplementary=false";

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
    api
      .get(url, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setAllPrograms(response.data);
        console.log(response.data);

        setPagination(JSON.parse(response.headers.pagination));
      });
  }, [url]);

  const fetchAllPrograms = () => {
    api
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAllPrograms(response.data);
        setPagination(JSON.parse(response.headers.pagination));
      });
  };

  function handleDeleteProgramInitial(programId) {
    setDeleteProgramId(programId);
    setIsDeleteProgram(true);
  }

  async function handleDeleteProgramFinal() {
    await api
      .post(
        `/workoutPrograms/delete-program?workoutProgramId=${deleteProgramId}&IsComplementary=false`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        fetchAllPrograms();
        setIsDeleteProgram(false);
      });
  }
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    console.log("allCategories:  ", allPrograms);
    console.log("result of dragable ", result);

    const items = Array.from(allPrograms);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAllPrograms(items);
    let rearrangeList = [];
    items.map((element, index) =>
      rearrangeList.push({
        Id: element.workoutProgramId,
        OrderNumber: index + 1,
      })
    );
    console.log("rearrangeList", rearrangeList);
    rearrangeProgramApi(rearrangeList);
  };
  const rearrangeProgramApi = (listOfPrograms) => {
    api
      .post("/workoutPrograms/update-program-order", listOfPrograms, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response Data", response);
        toast.success(response.data, {
          position: "top-right",
          autoClose: 2000,
          theme: "dark",
        });
      });
  };
  return (
    <div className="allProgramsPage">
      {isDeleteProgram && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Are you sure you want to delete
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              this program?
            </li>
            <div style={{ marginLeft: 100 }}>
              <button
                onClick={() => handleDeleteProgramFinal()}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ffba26",
                  color: "#0a2e28",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginRight: 30,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                Yes
              </button>
              <button
                onClick={() => setIsDeleteProgram(false)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#e63946",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginRight: 30,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}
      <div style={{ display: "flex", width: "100%" }}>
        <LeftNavigationBar allPrograms={1} />

        {/* <div style={{ width: windowSize.current[0], marginLeft: 300, display: 'flex', justifyContent: 'center' }}> */}
        <div className="mainBody">
          <div
            style={{
              marginTop: 30,
              marginLeft: 30,
              marginRight: 30,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              src={searchIcon}
              alt="searchIcon"
              style={{
                width: 25,
                height: 25,
                position: "absolute",
                marginLeft: 20,
                marginTop: 12,
              }}
            />
            <input
              type="search"
              placeholder="Search by Program Name"
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              style={{
                // marginLeft: 30,
                width: "50%",
                height: 50,
                paddingLeft: 60,
                paddingRight: 20,
                background: "#F5F5F5",
                borderStyle: "none",
                fontFamily: "LexendRegular400",
                fontSize: 14,
                // textTransform: 'capitalize'
              }}
            />

            <button
              onClick={() =>
                navigate("/add-new-program", {
                  state: { isAllPrograms: true },
                })
              }
              style={{
                cursor: "pointer",
                borderStyle: "none",
                width: 240,
                height: 50,
                backgroundColor: "#FFF2F2",
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                color: "#4176FF",
              }}
            >
              Add New Program
            </button>
            <button
              onClick={() => navigate("/categories")}
              style={{
                cursor: "pointer",
                borderStyle: "none",
                width: 160,
                height: 50,
                backgroundColor: "#FFF2F2",
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                color: "#FF0000",
              }}
            >
              Categories
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th style={{ fontFamily: "LexendRegular400" }}>ID</th>
                <th style={{ fontFamily: "LexendRegular400" }}>Program Name</th>
                <th style={{ fontFamily: "LexendRegular400" }}>Categories</th>
                <th style={{ fontFamily: "LexendRegular400" }}>Status</th>
                <th style={{ fontFamily: "LexendRegular400" }}>Actions</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="categories" direction="vertical">
                {(provided, snapshot) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {allPrograms.map((val, key) => (
                      <Draggable
                        key={val.workoutProgramId}
                        draggableId={val.workoutProgramId.toString()}
                        index={key}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style, // Keep existing styles
                              display: snapshot.isDragging
                                ? "table"
                                : "table-row", // Ensure it stays a table row
                            }}
                          >
                            <td style={{ textAlign: "left", paddingLeft: 5 }}>
                              {key + 1}
                            </td>
                            <td style={{ textAlign: "left", paddingLeft: 5 }}>
                              {val.workoutProgramTitle}
                            </td>
                            <td style={tableDataStyles}>
                              <span
                                style={{
                                  marginTop: 4,
                                  marginBottom: 4,
                                  marginRight: 8,
                                  height: 20,
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  background: "#52C0FD",
                                  borderRadius: 5,
                                  color: "white",
                                }}
                              >
                                All
                              </span>
                              {val.categories.map((category, key) => (
                                <span
                                  key={key}
                                  style={{
                                    marginTop: 4,
                                    marginBottom: 4,
                                    marginRight: 8,
                                    height: 20,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    background: "white",
                                    borderRadius: 5,
                                    color: "black",
                                  }}
                                >
                                  {category.workoutProgramCategoryName}
                                </span>
                              ))}
                            </td>
                            <td style={{ textAlign: "left", paddingLeft: 5 }}>
                              {val.workoutProgramStatus === 0
                                ? "Draft"
                                : "Published"}
                            </td>
                            <td style={{ textAlign: "left", paddingLeft: 5 }}>
                              <button
                                onClick={() =>
                                  navigate(
                                    `/preview-program/${val.workoutProgramId}`,
                                    {
                                      state: { isAllPrograms: true },
                                    }
                                  )
                                }
                                style={{
                                  backgroundColor: "white",
                                  width: 100,
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  borderStyle: "none",
                                  fontFamily: "LexendMedium500",
                                }}
                              >
                                Preview
                              </button>
                              <br />
                              <button
                                onClick={() =>
                                  handleDeleteProgramInitial(
                                    val.workoutProgramId
                                  )
                                }
                                style={{
                                  marginTop: 10,
                                  color: "#FF0000",
                                  backgroundColor: "#FFF2F2",
                                  width: 100,
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  borderStyle: "none",
                                  fontFamily: "LexendMedium500",
                                }}
                              >
                                Delete
                              </button>
                              <br />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
          {/* {allPrograms.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <button
                onClick={pagePrevious}
                style={{
                  cursor: "pointer",
                  width: 35,
                  height: 30,
                  borderWidth: 0,
                  backgroundColor: "#0a2e28",
                }}
              >
                <FiChevronLeft
                  size={24}
                  color="white"
                  style={{ marginTop: 2, marginLeft: -2 }}
                />
              </button>

              <input
                style={{
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 20,
                  width: 30,
                  textAlign: "center",
                  height: 25,
                  borderWidth: 1,
                  borderColor: "#999999",
                  borderStyle: "solid",
                }}
                value={page}
                onChange={pageChangeInput}
              />
              <div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>
                /
              </div>
              <div style={{ fontFamily: "LexendMedium500", fontSize: 14 }}>
                {pagination.totalPages}
              </div>
              <button
                onClick={pageNext}
                style={{
                  cursor: "pointer",
                  marginLeft: 20,
                  width: 35,
                  height: 30,
                  borderWidth: 0,
                  backgroundColor: "#0a2e28",
                }}
              >
                <FiChevronRight
                  size={24}
                  color="white"
                  style={{ marginTop: 2 }}
                />
              </button>
            </div>
          )} */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
