import "./Styles/AddNewProgram.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useLocation } from "react-router-dom";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import api from "../Contexts/BaseUrl";
import { useEffect, useState, useRef, createRef } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import Compressor from "compressorjs";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { uploadImage } from "../Components/helpers/fileUpload";

export default function AddNewProgram() {
  //Upload image to AWS Start
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  // Upload Image to AWS Ends

  const hiddenInputImageUpload = useRef(null);
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");
  const [key, setKey] = useState("");

  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
  };
  const handleChangeUploadedImage = (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setFile(newFile);
      setThumbnail(newFile);
      setFileupdated(newFile);
      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);
      setTempUploadedImageUrl(URL.createObjectURL(newFile));
      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
    }
    setShowReactCropper(true);
  };

  const handleCancelImage = () => {
    setTempUploadedImageUrl("");
    setFile(null);
    setThumbnail(null);
  };

  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [programTitle, setProgramTitle] = useState("");
  const [programSubtitle, setProgramSubtitle] = useState("");
  const [programTimeLength, setProgramTimeLength] = useState("");
  const [programDescription, setProgramDescription] = useState("");
  const [showReactCropper, setShowReactCropper] = useState(false);
  const [fileupdated, setFileupdated] = useState();
  const location = useLocation();
  const { state } = location;
  const cropperRef = createRef();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
  }, []);
  const [timeValidation, setTimeValidation] = useState("");
  useEffect(() => {
    if (showReactCropper) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showReactCropper]);

  const cancleCropperModal = () => {
    setShowReactCropper(false);
    setTempUploadedImageUrl("");
  };

  const compressImage = async (blob, size) => {
    return new Promise((resolve) => {
      new Compressor(blob, {
        // Adjust compression settings as needed
        quality: 0.9,
        maxWidth: size,
        maxHeight: size,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(async (blob) => {
        // Further compress the cropped image
        const compressedBlob = await compressImage(blob, 1000);
        const thumbnailBlob = await compressImage(blob, 300);
        const fixedAspectRatioImage = new File(
          [compressedBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );
        const fixedThumbnailImage = new File(
          [thumbnailBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );
        setTempUploadedImageUrl(URL.createObjectURL(fixedAspectRatioImage));
        setFile(fixedAspectRatioImage);
        setThumbnail(fixedThumbnailImage);
      }, fileupdated.type);
    }
    setShowReactCropper(false);
  };

  const handleCreateProgram = async (e) => {
    e.preventDefault();
    // validateTimeInput();

    setIsLoading(true);
    if (file === null) {
      await api
        .post(
          "/workoutPrograms/create-workout-program",
          {
            workoutProgramTitle: programTitle,
            workoutProgramSubtitle: programSubtitle,
            workoutProgramTimeLength: programTimeLength,
            workoutProgramDescription: programDescription,
            IsComplementary: !state.isAllPrograms,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setIsLoading(false);
          handleCreateWeek(response.data.workoutProgramId);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    } else {
      const imageResponse = await uploadImage(file);
      console.log("imageResponse", imageResponse.Location);

      const thumbnailResponse = await uploadImage(thumbnail);
      console.log("thumbnailResponse", thumbnailResponse.Location);

      await api
        .post(
          "/workoutPrograms/create-workout-program",
          {
            workoutProgramTitle: programTitle,
            workoutProgramSubtitle: programSubtitle,
            workoutProgramTimeLength: programTimeLength,
            workoutProgramDescription: programDescription,
            workoutProgramImageUrl: imageResponse.Location,
            workoutProgramThumbnailUrl: thumbnailResponse.Location,
            IsComplementary: !state.isAllPrograms,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // setIsLoading(false);
          handleCreateWeek(response.data.workoutProgramId);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  };

  async function handleCreateWeek(programId) {
    await api
      .post(`/weeks/create-week?programId=${programId}&weekNumber=${1}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        handleCreateWeekDays(response.data.weekId);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
      });
  }

  async function handleCreateWeekDays(weekId) {
    for (var i = 1; i <= 5; i++) {
      await api
        .post(
          `/days/create-day?weekId=${weekId}`,
          {
            dayNumber: i,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (i === 5) {
            setIsLoading(false);
            setisSuccess(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(false);
        });
    }
  }

  return (
    <div className="addNewProgramPage">
      {isSuccess && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Your program has been created
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              successfully and saved as Draft.
            </li>
            <button
              onClick={() => navigate(-1)}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#ffba26",
                color: "#0a2e28",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendMedium500",
                fontSize: 14,
                borderRadius: 4,
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}
      <div style={{ display: "flex" }}>
        {state.isAllPrograms ? (
          <LeftNavigationBar allPrograms={1} />
        ) : (
          <LeftNavigationBar ComplementaryPrograms={1} />
        )}

        <div className="mainBody">
          <div
            onClick={() => navigate("/all-programs")}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
            Add New Program
          </li>
          <input
            type="file"
            accept="image/*"
            ref={hiddenInputImageUpload}
            onChange={handleChangeUploadedImage}
            style={{ display: "none" }}
          />
          <form
            onSubmit={handleCreateProgram}
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            <div style={{ display: "flex" }}>
              {showReactCropper && (
                <div id="modal10" className="cropper-modal-wrapper">
                  <Cropper
                    ref={cropperRef}
                    zoomTo={0}
                    initialAspectRatio={16 / 11}
                    aspectRatio={16 / 11}
                    preview=".img-preview"
                    src={tempUploadedImageUrl}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    className="croppper-body"
                  />
                  <button
                    onClick={cancleCropperModal}
                    className="cropper-cancel-btn"
                  >
                    Cancel
                  </button>
                  <button className="cropper-submit" onClick={getCropData}>
                    Save
                  </button>
                </div>
              )}
              {tempUploadedImageUrl.length === 0 ? (
                <div
                  onClick={handleClickUploadImage}
                  style={{
                    width: 200,
                    background: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <img
                    key={key}
                    src={uploadImageIcon}
                    alt="uploadIcon"
                    style={{
                      maxHeight: 150,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 40,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 14,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Upload Image
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 40,
                    }}
                  >
                    Upload the thumb of the program
                  </li>
                </div>
              ) : (
                <div>
                  <div onClick={handleClickUploadImage} style={{}}>
                    <img
                      src={tempUploadedImageUrl}
                      alt="uploadedImage"
                      style={{
                        width: 200,
                        height: 200,
                        resize: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <button
                    onClick={handleCancelImage}
                    style={{
                      backgroundColor: "white",
                      paddingTop: 5,
                      paddingBottom: 5,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 10,
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              )}

              <div style={{ marginLeft: 30 }}>
                <input
                  required
                  placeholder="Program Title"
                  value={programTitle}
                  onChange={(e) => setProgramTitle(e.target.value)}
                  style={{
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <input
                  required
                  placeholder="Small Description"
                  value={programSubtitle}
                  onChange={(e) => setProgramSubtitle(e.target.value)}
                  style={{
                    marginTop: 25,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <div style={{ display: "flex", marginBottom: 25 }}>
                  {/* <img src={timerIcon} alt='timerIcon' style={{ position: 'absolute', maxHeight: 18, marginLeft: 20, marginTop: 35 }} /> */}
                  <input
                    required
                    placeholder="No of days"
                    value={programTimeLength}
                    onChange={(e) => setProgramTimeLength(e.target.value)}
                    // onBlur={validateTimeInput}
                    style={{
                      marginTop: 25,
                      width: 340,
                      height: 40,
                      borderStyle: "none",
                      paddingLeft: 20,
                      fontFamily: "LexendRegular400",
                    }}
                  />
                </div>
                {/* {state.isAllPrograms ? (
                  <label>
                    <input
                      style={{ marginRight: 10 }}
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    Add to complementary.
                  </label>
                ) : (
                  false
                )} */}
              </div>
              <div style={{ marginLeft: 70 }}>
                {!isLoading ? (
                  <button
                    type="submit"
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                  >
                    Create
                  </button>
                ) : (
                  <button
                    type="button"
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                  >
                    <Spinner
                      style={{
                        color: "white",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </button>
                )}
                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    Failed creating the program!
                  </li>
                )}
              </div>
            </div>
            <li
              onClick={() => console.log(file)}
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 24,
                marginTop: 60,
                marginBottom: 25,
              }}
            >
              Program Description
            </li>
            <textarea
              placeholder="Description"
              value={programDescription}
              onChange={(e) => setProgramDescription(e.target.value)}
              style={{
                paddingLeft: 20,
                paddingTop: 20,
                width: 570,
                height: 200,
                backgroundColor: "#ffffff",
                borderStyle: "none",
                fontFamily: "LexendRegular400",
                fontSize: 14,
              }}
            ></textarea>
          </form>
        </div>
      </div>
    </div>
  );
}
