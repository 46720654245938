import "./Styles/LeftNavigationBar.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import homeIcon from "../Images/LeftBarIcons/Home.png";
import allProgramsIcon from "../Images/LeftBarIcons/AllPrograms.png";
import userListIcon from "../Images/LeftBarIcons/UserList.png";
import equipmentIcon from "../Images/LeftBarIcons/Equipments.png";
import allAdminsIcon from "../Images/LeftBarIcons/AllAdmins.png";
import logoutIcon from "../Images/LeftBarIcons/Logout.png";
import chatIcon from "../Images/LeftBarIcons/chatbox.png";
import recipeIcon from "../Images/LeftBarIcons/recipe.svg";
import TrainrIcon from "../Images/LeftBarIcons/trainerIcon.png";
import tipIcon from "../Images/LeftBarIcons/tips.png";
import { BiUser } from "react-icons/bi";
import { BsTelephonePlus } from "react-icons/bs";
import { GoPackage } from "react-icons/go";

export default function LeftNavigationBar(props) {
  const navigate = useNavigate();
  const { editName } = props;
  const userObj = JSON.parse(localStorage.getItem("auth"));
  const userRole = userObj.role;
  const userName = userObj.name;
  const profileImageUrl = userObj.profileImageUrl;

  const [role, setRole] = useState("");
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState("");
  // const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isLogout, setIsLogout] = useState(false);
  const logout = () => {
    localStorage.removeItem("auth");
    setIsLogout(false);
    // window.location.reload();
    navigate("/login");
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
      setRole(user.role);
      setEmail(user.email);
    }
  }, []);

  function handleLogoutInitial() {
    setIsLogout(true);
  }

  return (
    <>
      <div className="leftNavigationBar">
        <div
          style={{
            width: 80,
            height: 80,
            borderRadius: 40,
            background: "#F5F5F5",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {profileImageUrl === null ? (
            <BiUser
              size={50}
              style={{
                color: "#F38CAC",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 20,
              }}
            />
          ) : (
            <img
              src={profileImageUrl}
              alt="profile"
              style={{ width: 80, height: 80, borderRadius: 40 }}
            />
          )}
        </div>

        <li
          onClick={() => navigate("/user-profile")}
          style={{
            cursor: "pointer",
            textAlign: "center",
            fontFamily: "LexendBold700",
            fontSize: 28,
          }}
        >
          {userName}
        </li>
        <li
          style={{
            textAlign: "center",
            fontFamily: "LexendBold400",
            fontSize: 14,
            color: "#88869A",
          }}
        >
          {userRole}
        </li>

        <div style={{ marginTop: 20, width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.home === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.home === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/")}
              src={homeIcon}
              alt="homeIcon"
              style={{
                width: 18,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Home
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.allPrograms === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.allPrograms === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/all-programs")}
              src={allProgramsIcon}
              alt="allProgramsIcon"
              style={{
                width: 18,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/all-programs")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              All Programs
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background:
                props.ComplementaryPrograms === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background:
                  props.ComplementaryPrograms === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/complementary-programs")}
              src={allProgramsIcon}
              alt="allProgramsIcon"
              style={{
                width: 18,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/complementary-programs")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Complementary Programs
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.usersList === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.usersList === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/users-list")}
              src={userListIcon}
              alt="userListIcon"
              style={{
                width: 20,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/users-list")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 13,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Users List
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.equipments === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.equipments === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/equipments")}
              src={equipmentIcon}
              alt="equipmentIcon"
              style={{
                width: 18,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/equipments")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Equipments
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.recipe === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.recipe === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/recipe")}
              src={recipeIcon}
              alt="recipeIcon"
              style={{
                width: 18,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/recipe")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Recipe
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.instagramRecipe === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.instagramRecipe === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/instagram-recipe")}
              src={recipeIcon}
              alt="recipeIcon"
              style={{
                width: 18,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/instagram-recipe")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Instagram Recipe
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.trainer === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.trainer === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/recipe")}
              src={TrainrIcon}
              alt="trainerIcon"
              style={{
                width: 18,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/trainer")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Trainer
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.tips === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.tips === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/tips")}
              src={tipIcon}
              alt="recipeIcon"
              style={{
                width: 18,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/tips")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Tips
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.allAdmins === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.allAdmins === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/all-admins")}
              src={allAdminsIcon}
              alt="allAdminsIcon"
              style={{
                width: 18,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/all-admins")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              All Admins
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.packages === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.packages === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <GoPackage
              size={18}
              style={{ marginLeft: "20%", cursor: "pointer" }}
              onClick={() => navigate("/contact")}
            />
            <li
              onClick={() => navigate("/packages")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Subscription
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.contact === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.contact === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            {/* <img onClick={() => navigate('/all-admins')} src={allAdminsIcon} alt='allAdminsIcon' style={{ width: 18, height: 18, marginLeft: '20%', cursor: 'pointer' }} /> */}
            <BsTelephonePlus
              size={18}
              style={{ marginLeft: "20%", cursor: "pointer" }}
              onClick={() => navigate("/contact")}
            />
            <li
              onClick={() => navigate("/contact")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Contact Us
            </li>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 50,
              background: props.allMessages === 1 ? "#fdeff4" : "#ffffff",
            }}
          >
            <div
              style={{
                width: 8,
                height: "100%",
                background: props.allMessages === 1 ? "#f48bab" : "#ffffff",
              }}
            />
            <img
              onClick={() => navigate("/chat")}
              src={chatIcon}
              alt="chatbox"
              style={{
                width: 20,
                height: 18,
                marginLeft: "20%",
                cursor: "pointer",
              }}
            />
            <li
              onClick={() => navigate("/chat")}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 13,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Chatbox
            </li>
          </div>
          <div
            style={{
              marginTop: 0,
              display: "flex",
              alignItems: "center",
              height: 50,
            }}
          >
            <div style={{ width: 8, height: "100%", background: "#ffffff" }} />
            <img
              onClick={() => handleLogoutInitial()}
              src={logoutIcon}
              alt="logoutIcon"
              style={{
                cursor: "pointer",
                width: 18,
                height: 18,
                marginLeft: "20%",
              }}
            />
            <li
              onClick={() => handleLogoutInitial()}
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                color: "#FF0000",
                marginLeft: 15,
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              Log Out
            </li>
          </div>
        </div>
      </div>
      {isLogout && (
        <>
          {" "}
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div
            id="modal"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: 140,
              width: 300,
            }}
          >
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 15,
              }}
            >
              Are you sure you want to logout?
            </li>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 40,
                gap: 30,
              }}
            >
              <button
                onClick={() => logout()}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ffba26",
                  color: "#0a2e28",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                Yes
              </button>
              <button
                onClick={() => setIsLogout(false)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#e63946",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
