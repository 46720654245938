import "./Styles/AddNewProgram.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { useEffect, useState } from "react";
import "react-activity/dist/library.css";
import "cropperjs/dist/cropper.css";
import { isVimeoVideo } from "../Components/helpers/fileUpload";
export default function PreviewRecipe() {
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");

  const navigate = useNavigate();
  const [programTitle, setProgramTitle] = useState("");
  const [programSubtitle, setProgramSubtitle] = useState("");
  const [programTimeLength, setProgramTimeLength] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [methods, setMethods] = useState([]);
  const [categoryDataList, setCategoryDataList] = useState([]);
  const [tempUploadedVideoUrl, setTempUploadedVideoUrl] = useState(null);
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const { state } = location;
  const urlId = useParams();
  const VIDEO_URL = "https://d2zw1jyj1gqhys.cloudfront.net/";

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    api
      .get(
        state.isRecipe
          ? `/receipe/get-receipe-by-id?receipeId=${urlId.recipeId}`
          : `/InstagramReceipe/get-receipe-by-id?receipeId=${urlId.recipeId}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      )
      .then((response) => {
        setTempUploadedImageUrl(response.data.imageUrl);
        setProgramTitle(response.data.title);
        setProgramSubtitle(response.data.timeToPrepare);
        setProgramTimeLength(response.data.servings);
        setIngredients(JSON.parse(response.data.ingredients));
        setMethods(JSON.parse(response.data.methods));
        setCategoryDataList(response.data.categoryDataList);
        setTempUploadedVideoUrl(response.data.videoUrl);
        console.log("response.data.videoUrl", response.data.videoUrl);
        console.log("pop", response.data.videoUrl.split("/").pop());
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="addNewProgramPage">
      <div style={{ display: "flex" }}>
        {state.isRecipe ? (
          <LeftNavigationBar recipe={1} />
        ) : (
          <LeftNavigationBar instagramRecipe={1} />
        )}

        <div className="mainBody">
          <div
            onClick={() =>
              state.isRecipe
                ? navigate("/recipe")
                : navigate("/instagram-recipe")
            }
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
            Preview Recipe
          </li>
          <form style={{ marginTop: 30, marginBottom: 30 }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: 200,
                  cursor: "pointer",
                }}
              >
                <img
                  src={tempUploadedImageUrl}
                  alt="RecipeImage"
                  style={{
                    height: 180,
                    width: 180,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
              <div style={{ marginLeft: 30 }}>
                <input
                  required
                  disabled
                  placeholder="Recipe Title"
                  value={programTitle}
                  onChange={(e) => setProgramTitle(e.target.value)}
                  style={{
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <input
                  required
                  disabled
                  placeholder="Time to Prepare"
                  value={programSubtitle}
                  onChange={(e) => setProgramSubtitle(e.target.value)}
                  style={{
                    marginTop: 25,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <div style={{ display: "flex" }}>
                  <input
                    required
                    disabled
                    placeholder="Servings"
                    value={programTimeLength}
                    onChange={(e) => setProgramTimeLength(e.target.value)}
                    style={{
                      marginTop: 25,
                      width: 340,
                      height: 40,
                      borderStyle: "none",
                      paddingLeft: 20,
                      fontFamily: "LexendRegular400",
                    }}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 70 }}>
                <button
                  onClick={() =>
                    navigate(`/edit-recipe/${urlId.recipeId}`, {
                      state: {
                        isRecipe: state.isRecipe,
                      },
                    })
                  }
                  style={{
                    marginTop: 25,
                    width: 170,
                    height: 50,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "#EAF0FF",
                    fontFamily: "LexendBold700",
                    fontSize: 18,
                    color: "#4176FF",
                    borderStyle: "none",
                  }}
                >
                  Edit
                </button>
                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    Failed creating the program!
                  </li>
                )}
              </div>
            </div>
            <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 20,
                marginTop: 30,
                marginBottom: 25,
              }}
            >
              Categories
            </li>
            {categoryDataList?.map((val, key) => {
              return (
                <span
                  key={key}
                  style={{
                    marginTop: 4,
                    marginBottom: 4,
                    marginRight: 8,
                    height: 20,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    background: "white",
                    borderRadius: 5,
                    color: "black",
                  }}
                >
                  {val.name}
                </span>
              );
            })}
            <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 20,
                marginTop: 30,
                marginBottom: 25,
              }}
            >
              Recipe Video
            </li>
            {tempUploadedVideoUrl ? (
              // <VimeoPlayer
              //   video={tempUploadedVideoUrl}
              //   autoplay
              //   className="video-wrapper"
              // />
              <video width="250" height="200" controls>
                <source
                  src={`${VIDEO_URL + isVimeoVideo(tempUploadedVideoUrl)}`}
                />
              </video>
            ) : (
              <p> No Video Found </p>
            )}
            <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 24,
                marginTop: 60,
                marginBottom: 25,
              }}
            >
              Recipe Description
            </li>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: 240,
                  backgroundColor: "#ffffff",
                  padding: 20,
                }}
              >
                <li
                  style={{
                    fontFamily: "LexendSemiBold600",
                    fontSize: 20,
                    marginBottom: 25,
                  }}
                >
                  Ingredients
                </li>
                <ul>
                  {ingredients.map((item, index) => (
                    <li
                      key={index}
                      style={{
                        paddingBottom: 2,
                      }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div
                style={{
                  width: 385,
                  marginLeft: 20,
                  backgroundColor: "#ffffff",
                  padding: 20,
                }}
              >
                <li
                  style={{
                    fontFamily: "LexendSemiBold600",
                    fontSize: 20,
                    marginBottom: 25,
                  }}
                >
                  Methods
                </li>
                <ol>
                  {methods.map((item) => (
                    <li>{item}</li>
                  ))}
                </ol>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
